<template>
  <div class="container-fluid">
    <div class="container" :class="{ halfCont: channel.SideBar }">
      <div id="contentPage">
        <h1>
          {{ channel.Title }}
        </h1>
        <live-video-player
          :source="channel.Source"
          :poster="channel.Poster"
          :vwidth="channel.Width"
          :vheight="channel.Height"
          :hdvideoplayer="channel.hdvideoplayer"
        ></live-video-player>
      </div>

      <article-sidebar v-if="channel.SideBar" />
    </div>
  </div>
</template>

<script>
  import eventBus from "../eventBus";
  import LangMixin from "../mixins/LangMixin";
  import ArticleSidebar from "../components/ArticleSidebar";
  import LiveVideoPlayer from "../components/LiveVideoPlayer";
  //import $ from 'jquery';
  //import 'magnific-popup';
  //import 'magnific-popup/dist/magnific-popup.css';
  import ArticleMixin from "../mixins/ArticleMixin";

  export default {
    mixins: [
        LangMixin,
        ArticleMixin
    ],

    components: {
      ArticleSidebar,
      LiveVideoPlayer
    },

    props: {
      channel:{
        type: Object,
        required: true
      },
    },

    mounted() {
      eventBus.$on('langChange',  () => this.$router.push({ name: 'Home'}));
      this.$emit('pageLoaded');
    },

    
  }
</script>
