<template>
  <div class="container">
    <div id="contentPage">
      <h1>
        {{ title[lang] }}
      </h1>

      <div id="contentTopDiv">
        <featured-box
            v-if="Object.keys(featuredItem).length"
            :url="resolveUrl(featuredItem)"
            :img="resolveBgmg(featuredItem, 'high')"
            :disabled="['tag']"
        >
          <template v-slot:title>
            {{ resolveTitle(featuredItem) }}
          </template>

          <template v-slot:description>
            {{ resolveDate(featuredItem) }}
          </template>
        </featured-box>

        <medium-box
            v-for="(mainItem, key) in mainItems"
            :key="key"
            :url="resolveUrl(mainItem)"
            :img="resolveBgmg(mainItem)"
            :disabled="['tag']"
            :class="{ 'last' : (key + 1) % 2 === 0}"
        >
          <template v-slot:title>
            {{ resolveTitle(mainItem) }}
          </template>

          <template v-slot:description>
            {{ resolveDate(mainItem) }}
          </template>
        </medium-box>
      </div>

      <div class="NewsDiv20">
        <medium-box
            v-for="(sideItem, key) in sideItems"
            :key="key"
            :url="resolveUrl(sideItem)"
            :img="resolveBgmg(sideItem)"
            :disabled="['tag']"
        >
          <template v-slot:title>
            {{ resolveTitle(sideItem) }}
          </template>

          <template v-slot:description>
            {{ resolveDate(sideItem) }}
          </template>
        </medium-box>

        <infinite-loading v-if="sideItems.length" @infinite="requestPlaylist">
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import LangMixin from "../mixins/LangMixin";
import FeaturedBox from "../components/FeaturedBox";
import MediumBox from "../components/MediumBox";
import CollectionMixin from "../mixins/CollectionMixin";
import eventBus from "../eventBus";
import InfiniteLoading from 'vue-infinite-loading';
import ArticleMixin from "../mixins/ArticleMixin";

export default {
  components: {
    FeaturedBox,
    MediumBox,
    InfiniteLoading
  },

  mixins: [
    LangMixin,
    CollectionMixin,
    ArticleMixin
  ],

  mounted() {
    this.initData();
    this.requestPlaylist();

    eventBus.$on('langChange',  ({ lang, history }) => {
      if (! history) {
        this.$router.push(
            {
              name: `emisija-tv-${lang}`,
              params: { slug : this.translatedSlug }
            }
        )
      }
    })
  },

  data() {
    return {
      title : {
        lat : '',
        cir : ''
      },
      translatedSlug : ''
    }
  },

  methods: {
    resolveUrl(item) {
      return `https://www.youtube.com/watch?v=${item.snippet.resourceId.videoId}`
    },

    resolveBgmg(item, size = 'medium') {
      return { backgroundImage : `url('${item.snippet.thumbnails[size].url}')` }
    },

    resolveTitle(item) {
      return item.snippet.title;
    },

    resolveDescription(item) {
      return item.snippet.description;
    },

    setSideItemData({items, nextPageToken}) {
      this.sideItems.push(...items);
      this.pagination.nextPage = nextPageToken;
    },

    requestPlaylist($state) {
      let url = `plist?url=${this.$route.params.slug}`;

      if (this.pagination.nextPage) {
        url += `&page=${this.pagination.nextPage}`;
      }

      this.requestData(url, data => {
        if (! data.error) {
          if(this.firstPage) {
            this.featuredItem = data.items[0];
            this.mainItems.push(...data.items.slice(1, 5));
            data.items = data.items.slice(5)
            this.$emit('pageLoaded');
          }

          this.setPageData(data);
          this.setSideItemData(data);
        }else {
          this.$router.push({ name : 'not_found' })
        }

        this.loadMore = ! data.error && data.nextPageToken
      }, $state)
    },

    setPageData({title, titlec, url, urlc}) {
      this.title = {
        lat : title,
        cir : titlec
      };
      this.translatedSlug = this.lang === 'lat' ? urlc : url;
    },

    resolveDate(item) {
      return this.getFormatedDate(item.snippet.publishedAt);
    }
  }
}
</script>